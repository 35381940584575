@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: Grotesk, sans-serif;
  line-height: 120%;
}

.text-gradient {
  background: linear-gradient(111deg, #f2c553 13.59%, #fff 82.2%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stroke-icon {
  stroke-width: 1.33px;
}

.rt-AlertDialogOverlay {
  z-index: 101;
}

.rt-BaseDialogOverlay {
  z-index: 102;
}

.date-picker {
  background-color: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09) !important;
}
